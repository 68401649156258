import React from "react";
import styles from "./grossprofit.module.scss";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import muiTheme from "../../../utils/muiTheme";
import NumberFormat from "react-number-format";

class GrossProfit extends React.Component {
  render() {
    const { answers } = this.props;

    const totalSales = answers[10] ? answers[10].value : null;
    const costOfGoods = answers[11] ? answers[11].value : null;

    if (!totalSales || !costOfGoods) {
      return "";
    }

    const grossProfit = Number((totalSales - costOfGoods).toFixed(2));
    const margin = Number(((grossProfit / totalSales) * 100).toFixed(2));

    return (
      <MuiThemeProvider theme={muiTheme}>
        <div className={styles.container}>
          <span>
            It seems that your Gross Profit is
            <strong>
              &nbsp;
              <NumberFormat
                value={grossProfit}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
              &nbsp;
            </strong>
            and margin roughly
            <strong>
              &nbsp;
              <NumberFormat
                value={margin}
                displayType={"text"}
                thousandSeparator={true}
                suffix={"%"}
              />
              &nbsp;
            </strong>
            .<br />
            <br />
            If that doesn't seem right, you'll need to edit the numbers above!
          </span>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default GrossProfit;
